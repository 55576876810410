define('ember-moment/utils/make-bound-helper', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = function (helper) {
    if (_ember['default'].Helper) {
      if (_ember['default'].Helper.detect(helper)) {
        return helper;
      } else {
        return _ember['default'].Helper.helper(helper);
      }
    }

    return _ember['default'].HTMLBars.makeBoundHelper(helper);
  };
});