define('ember-moment/helpers/moment-duration', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function durationHelper(params, hash) {
    if (params.length > 2) {
      throw new TypeError('ember-moment: Invalid Number of arguments, at most 2');
    }

    var time = _moment['default'].duration.apply(_moment['default'], _toConsumableArray(params));

    if (hash.locale) {
      time = time.locale(hash.locale);
    }

    return time.humanize();
  }

  exports['default'] = durationHelper;
});