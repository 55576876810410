define('ember-countries/utils/country-properties', ['exports', 'ember-countries/utils/countries-lists'], function (exports, _emberCountriesUtilsCountriesLists) {
  'use strict';

  exports.countryIsoToValue = countryIsoToValue;
  exports.isCountryWithoutZip = isCountryWithoutZip;
  exports.isCountryWithState = isCountryWithState;

  function countryIsoToValue(iso) {
    // if there is no iso or no country we default to the ISO country.
    var countrySelected = _emberCountriesUtilsCountriesLists.countries.findBy('iso', (iso || "").toUpperCase());
    return countrySelected ? countrySelected.country ? countrySelected.country : (iso || "").toUpperCase() : (iso || "").toUpperCase();
  }

  function isCountryWithoutZip(iso) {
    // return true if the country has not a required ZIP code
    return _emberCountriesUtilsCountriesLists.countriesWithoutZip.indexOf((iso || "").toUpperCase()) >= 0;
  }

  function isCountryWithState(iso) {
    // return true if the country has a required State.
    return _emberCountriesUtilsCountriesLists.countriesWithState.indexOf((iso || "").toUpperCase()) >= 0;
  }
});