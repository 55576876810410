define('ember-moment/helpers/moment-from-now', ['exports', 'ember', 'moment', 'ember-moment/utils/compute-fn'], function (exports, _ember, _moment, _emberMomentUtilsComputeFn) {
  'use strict';

  exports.helperFactory = helperFactory;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var runLater = _ember['default'].run.later;

  function helperFactory() {
    if (_ember['default'].Helper) {
      return _ember['default'].Helper.extend({
        compute: (0, _emberMomentUtilsComputeFn['default'])(function (params, hash) {
          if (hash.interval) {
            runLater(this, this.recompute, parseInt(hash.interval, 10));
          }

          var time = _moment['default'].apply(undefined, _toConsumableArray(params));
          if (hash.locale) {
            time = time.locale(hash.locale);
          }
          return time.fromNow(hash.hideSuffix);
        })
      });
    }

    return (0, _emberMomentUtilsComputeFn['default'])(function (params, hash) {
      var time = _moment['default'].apply(undefined, _toConsumableArray(params));
      if (hash.locale) {
        time = time.locale(hash.locale);
      }
      return time.fromNow(hash.hideSuffix);
    });
  }

  exports['default'] = helperFactory();
});