define('ember-moment/helpers/moment-format', ['exports', 'moment', 'ember-moment/utils/compute-fn'], function (exports, _moment, _emberMomentUtilsComputeFn) {
  'use strict';

  exports['default'] = helperFactory;

  function helperFactory() {
    var globalOutputFormat = arguments.length <= 0 || arguments[0] === undefined ? 'LLLL' : arguments[0];

    return (0, _emberMomentUtilsComputeFn['default'])(function (params, hash) {
      var length = params.length;

      if (length > 3) {
        throw new TypeError('ember-moment: Invalid Number of arguments, expected at most 3');
      }

      var output = undefined;
      var args = [];

      args.push(params[0]);

      if (length === 1) {
        output = globalOutputFormat;
      } else if (length === 2) {
        output = params[1];
      } else if (length > 2) {
        args.push(params[2]);
        output = params[1];
      }

      var time = _moment['default'].apply(undefined, args);
      if (hash.locale) {
        time = time.locale(hash.locale);
      }

      return time.format(output);
    });
  }
});