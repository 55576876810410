define('ember-moment/utils/compute-fn', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = function (cb) {
    return function (params, hash) {
      if (!params || params && params.length === 0) {
        throw new TypeError('ember-moment: Invalid Number of arguments, expected at least 1');
      }

      var datetime = params[0];

      if ([null, '', undefined].indexOf(datetime) > -1 && (hash.allowEmpty || hash['allow-empty'])) {
        _ember['default'].Logger.warn('ember-moment: an empty value (null, undefined, or "") was passed to moment-format');
        return;
      }

      return cb.apply(this, arguments);
    };
  };
});