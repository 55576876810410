define('ember-cli-font-awesome/helpers/fa-icon', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  var FA_PREFIX = /^fa\-.+/;

  var warn = _ember['default'].Logger.warn;

  /**
   * Handlebars helper for generating HTML that renders a FontAwesome icon.
   *
   * @param  {String} name    The icon name. Note that the `fa-` prefix is optional.
   *                          For example, you can pass in either `fa-camera` or just `camera`.
   * @param  {Object} options Options passed to helper.
   * @return {Ember.Handlebars.SafeString} The HTML markup.
   */
  var faIcon = function faIcon(_ref, params) {
    var _ref2 = _slicedToArray(_ref, 1);

    var name = _ref2[0];

    if (_ember['default'].typeOf(name) !== 'string') {
      var message = "fa-icon: no icon specified";
      warn(message);
      return _ember['default'].String.htmlSafe(message);
    }

    var classNames = [],
        html = "";

    classNames.push("fa");
    if (!name.match(FA_PREFIX)) {
      name = "fa-" + name;
    }
    classNames.push(name);
    if (params.spin) {
      classNames.push("fa-spin");
    }
    if (params.pulse) {
      classNames.push("fa-pulse");
    }
    if (params.flip) {
      classNames.push("fa-flip-" + params.flip);
    }
    if (params.rotate) {
      classNames.push("fa-rotate-" + params.rotate);
    }
    if (params.lg) {
      warn("fa-icon: the 'lg' parameter is deprecated. Use 'size' instead. I.e. {{fa-icon size=\"lg\"}}");
      classNames.push("fa-lg");
    }
    if (params.x) {
      warn("fa-icon: the 'x' parameter is deprecated. Use 'size' instead. I.e. {{fa-icon size=\"" + params.x + "\"}}");
      classNames.push("fa-" + params.x + "x");
    }
    if (params.size) {
      if (_ember['default'].typeOf(params.size) === "string" && params.size.match(/^\d+$/)) {
        params.size = Number(params.size);
      }
      if (_ember['default'].typeOf(params.size) === "number") {
        classNames.push("fa-" + params.size + "x");
      } else {
        classNames.push("fa-" + params.size);
      }
    }
    if (params.fixedWidth) {
      classNames.push("fa-fw");
    }
    if (params.listItem) {
      classNames.push("fa-li");
    }
    if (params.pull) {
      classNames.push("pull-" + params.pull);
    }
    if (params.border) {
      classNames.push("fa-border");
    }
    if (params.stack) {
      if (_ember['default'].typeOf(params.stack) === "string" && params.stack.match(/^\d+$/)) {
        params.size = Number(params.stack);
      }
      if (_ember['default'].typeOf(params.stack) === "number") {
        classNames.push("fa-stack-" + params.stack + "x");
      } else {
        classNames.push("fa-stack-" + params.stack);
      }
    }
    if (params.inverse) {
      classNames.push("fa-inverse");
    }
    if (params.classNames && !_ember['default'].isArray(params.classNames)) {
      params.classNames = [params.classNames];
    }
    if (!_ember['default'].isEmpty(params.classNames)) {
      Array.prototype.push.apply(classNames, params.classNames);
    }

    html += "<";
    var tagName = params.tagName || 'i';
    html += tagName;
    html += " class='" + classNames.join(" ") + "'";
    if (params.title) {
      html += " title='" + params.title + "'";
    }
    if (params.ariaHidden === undefined || params.ariaHidden) {
      html += " aria-hidden=\"true\"";
    }
    html += "></" + tagName + ">";
    return _ember['default'].String.htmlSafe(html);
  };

  exports.faIcon = faIcon;
  exports.faIcon = faIcon;
  exports['default'] = _ember['default'].Helper.helper(faIcon);
});